// import logo from './logo.svg';
import './App.css';
import React from 'react';

function App() {
  React.useEffect(() => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.impacto.kakutor';
  }, []);
  return (
    <></>
  );
}

export default App;
